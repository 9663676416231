(function($) {
	Common = {
		init: function() {
			this.anchor()
			this.scrollMagic()
			this.headDropDown()
			this.noLink()
			this.headerNav()
			this.footerDrop()
			this.qaAccordion()
			this.faq()
			this.onClk()
			this.app()
		},
		modalSlick: function(){
			$('.modalContentSlick').slick({
			  dots: true,
			  infinite: false,
			  speed: 500,
			  slidesToShow: 1,
			  touchMove: true,
			  draggable: true,
			});

			$('.modalContentSlick').on('afterChange', function(event, slick, currentSlide, nextSlide){
			  if (currentSlide == slick.$slides.length - 1) {
			  	console.log("LASt");
				if (!$('.modalOption .box').hasClass('active')) {
					$('.modalOption .box').addClass('active')
				}
			  }
			});

			$('.checkbox').on('click', function(){
				if ($(this).hasClass('active')) {
					$(this).removeClass('active')
					$('.modalBtn').addClass('nonActive')
				}
				else{
					$(this).addClass('active')
					$('.modalBtn').removeClass('nonActive')
				}

			})

			$('.modalClose').on('click', function(){
				$('#modalRule').delay(400).fadeOut()
				$('.modalContainer').fadeOut()
			})

			$('.showModalRule').on('click', function(e){
				e.preventDefault()
				$('#modalRule').fadeIn()
				$('.modalContainer').delay(400).fadeIn()
				setTimeout(function(){
					$('.modalContentSlick').slick('setPosition');
				}, 500)

			})
		},
		app: function(){
			var $customSelect = $('.custom-select')
			var $googlePlay = $('.googlePlay')
			var $appStore = $('.appStore')

			$customSelect.on('click', function(){
				var choice = $('.same-as-selected').text()
				if (choice == 'いずれにも該当しない' ) {
					$googlePlay.attr('href', 'https://play.google.com/store/apps/details?id=com.fandmnet.marunage.fkss')
					$appStore.attr('href','https://apps.apple.com/jp/app/id1259124658')
				}
				else{
					$googlePlay.attr('href', 'https://play.google.com/store/apps/details?id=com.fandmnet.marunage')
					$appStore.attr('href','https://apps.apple.com/jp/app/id1128303028')
				}
				$googlePlay.fadeIn(500)
				$appStore.fadeIn(500)
			})
		},
		advantage: function(){
			var mouseX;
			try {
				$('.advantage-slick').slick({
				  dots: true,
				  infinite: true,
				  speed: 500,
				  slidesToShow: 1,
				  touchMove: false,
				  draggable: false
				});
			} catch (e) {
				console.log(e.message);
			}

			$('.slick-next').on('click', function(){
				$('.advantage-compare-txt span').removeClass('active')
				$('.calqAdv').addClass('active')
			})
			$('.slick-prev').on('click', function(){
				$('.advantage-compare-txt span').removeClass('active')
				$('.calqAdv').addClass('active')
			})
			$('.slick-dots li button').on('click', function(){
				$('.advantage-compare-txt span').removeClass('active')
				$('.calqAdv').addClass('active')
			})
			$("#czone1").mousemove( function(e) {
				$('.advantage-compare-txt span').removeClass('active')
				var mouseX = e.pageX;
				var mouseY = e.pageY;
				var left = $(this).offset().left;
				var top = $(this).offset().top;
				mouseX = (mouseX - left);
				mouseY = (mouseY - top);

				if (mouseX >= 400) {
					$('.calqAdv').addClass('active')
				}
				else{
					$('.otherAdv').addClass('active')
				}
				mouseX = mouseX + "px";
				$(this).find(".div-one").css("width", mouseX);
				// $(this).find('.handle').hide()
				$(this).find('.handle').css({
					top : mouseY,
					left : mouseX
				});
				$(this).addClass('inited');
			});
			$("#czone2").mousemove( function(e) {
				$('.advantage-compare-txt span').removeClass('active')
				var mouseX = e.pageX;
				var left = $(this).offset().left;
				mouseX = (mouseX - left)

				if (mouseX >= 400) {
					$('.calqAdv').addClass('active')
				}
				else{
					$('.otherAdv').addClass('active')
				}
				mouseX = mouseX + "px";
				$(this).find(".div-one").css("width", mouseX);
				$(this).find('.handle').hide()
			});
			$("#czone3").mousemove( function(e) {
				$('.advantage-compare-txt span').removeClass('active')
				var mouseX = e.pageX;
				var left = $(this).offset().left;
				mouseX = (mouseX - left)

				if (mouseX >= 400) {
					$('.calqAdv').addClass('active')
				}
				else{
					$('.otherAdv').addClass('active')
				}
				mouseX = mouseX + "px";
				$(this).find(".div-one").css("width", mouseX);
				$(this).find('.handle').hide()
			});


		},
		onClk: function(){
			var $target = $('.onClk')

			$target.on('click', function(){
				window.location.href = $(this).data('link')
			})
		},
		faq: function(){
			var $target = $('.faqMenu')
			var $qaContentBlock = $('.qa-content-block');
			var dataLink = "";

			$target.on('click', function(){
				if ($('.noSubmitFaq').hasClass('running')) {
					$('.qa-content-list dl').delay(300).fadeIn()
				}
				$('.qa-search-result').fadeOut(300)
				dataLink = $(this).data('link')
				$target.removeClass('active');
				$(this).delay(100).addClass('active');
				setTimeout(function(){
					$qaContentBlock.each(function(){
						if ($(this).data('relatedlink') !== dataLink) {
							$(this).fadeOut(100)
						}
						else{
							$(this).delay(300).fadeIn(300)
						}
					})
				},200)
			})

			var $faqSrc = $('.faqSrc')
			var value = ""
			var block = ""
			var splitValue = []
			$('.noSubmitFaq').on('click', function(e){
				$(this).addClass('running')
				e.preventDefault()
				value = $faqSrc.val()
				$target.removeClass('active');
				if (value !== "") {
					$('.qa-content-list dl').removeClass('result')
					$qaContentBlock.fadeOut(300);
					$qaContentBlock.each(function(){
						$(this).find('.qa-content-list dl').each(function(){
							block = $(this).find('.qa-data-for-search').text()
							if (block.indexOf(value) !== -1) {
								$(this).addClass('result')
							}

							// splitValue = value.split('');
							// for (var i = 0; i < splitValue.length; i++) {
							// 	console.log(splitValue[i]);
							// 	if (block.indexOf(splitValue[i]) !== -1) {
							// 		$(this).addClass('result')
							// 	}
							// }

						})
					})
					setTimeout(function(){
						$('.qa-search-result').find('span').text(value)
						$('.qa-search-result').fadeIn(300)
						$('.result').parent().parent().show()
						$('.qa-content-list dl').hide()
						$('.result').fadeIn(400)
						$('html, body').delay(300).animate({
							scrollTop: $('.qa-search-result').offset().top - 100
						}, 500);
					},
					1000)
				}
				else{
					$qaContentBlock.hide()
					$('.qa-search-result').fadeOut(300)
					$('.qa-content-list dl').show().removeClass('result')
					$qaContentBlock.delay(300).fadeIn(300);
				}
			})
		},
		qaAccordion: function(){
			var $target = $('.qa-content-list dl dt')

			$target.on('click', function(){
				$(this).siblings('dd').slideToggle(500)
				if ($(this).hasClass('open')) {
					$(this).removeClass('open')
				}
				else{
					$(this).addClass('open')
				}
			})
		},
		customSelect: function() {
			var x, i, j, selElmnt, a, b, c;
			/* Look for any elements with the class "custom-select": */
			x = document.getElementsByClassName("custom-select");
			for (i = 0; i < x.length; i++) {
				selElmnt = x[i].getElementsByTagName("select")[0];
				/* For each element, create a new DIV that will act as the selected item: */
				a = document.createElement("DIV");
				a.setAttribute("class", "select-selected");
				a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
				x[i].appendChild(a);
				/* For each element, create a new DIV that will contain the option list: */
				b = document.createElement("DIV");
				b.setAttribute("class", "select-items select-hide");
				for (j = 1; j < selElmnt.length; j++) {
					/* For each option in the original select element,
					create a new DIV that will act as an option item: */
					c = document.createElement("DIV");
					c.innerHTML = selElmnt.options[j].innerHTML;
					c.addEventListener("click", function(e) {
						/* When an item is clicked, update the original select box,
						and the selected item: */
						var y, i, k, s, h;
						s = this.parentNode.parentNode.getElementsByTagName("select")[0];
						h = this.parentNode.previousSibling;
						for (i = 0; i < s.length; i++) {
							if (s.options[i].innerHTML == this.innerHTML) {
								s.selectedIndex = i;
								h.innerHTML = this.innerHTML;
								y = this.parentNode.getElementsByClassName("same-as-selected");
								for (k = 0; k < y.length; k++) {
									y[k].removeAttribute("class");
								}
								this.setAttribute("class", "same-as-selected");
								break;
							}
						}
						h.click();
					});
					b.appendChild(c);
				}
				x[i].appendChild(b);
				a.addEventListener("click", function(e) {
					/* When the select box is clicked, close any other select boxes,
					and open/close the current select box: */
					e.stopPropagation();
					closeAllSelect(this);
					this.nextSibling.classList.toggle("select-hide");
					this.classList.toggle("select-arrow-active");
				});

				$('.select-selected').on('click', function(){
					$(this).addClass('active')
					$(this).siblings('.select-items').addClass('active')
				})
			}

			function closeAllSelect(elmnt) {
				/* A function that will close all select boxes in the document,
				except the current select box: */
				var x, y, i, arrNo = [];
				x = document.getElementsByClassName("select-items");
				y = document.getElementsByClassName("select-selected");
				for (i = 0; i < y.length; i++) {
					if (elmnt == y[i]) {
						arrNo.push(i)
					} else {
						y[i].classList.remove("select-arrow-active");
					}
				}
				for (i = 0; i < x.length; i++) {
					if (arrNo.indexOf(i)) {
						x[i].classList.add("select-hide");
					}
				}
			}

			/* If the user clicks anywhere outside the select box,
			then close all select boxes: */
			document.addEventListener("click", closeAllSelect);
		},
		footerDrop: function() {
			var $target = $('.spMenuDrop')

			$target.on('click', function() {
				if (!$(this).hasClass("open")) {
					$(this).addClass('open')
					$(this).find(".plus").addClass('open')
					$(this).siblings('.spmenu-drop').slideDown(500)
				} else {
					$(this).removeClass('open')
					$(this).find(".plus").removeClass('open')
					$(this).siblings('.spmenu-drop').slideUp(500)
				}
			})
		},
		headerNav: function() {
			var $target = $('.menuBtn')
			var $menuDropBlock = $('.menuDropBlock')
			var header = $('header')

			$target.on('click', function() {
				if (!$(this).hasClass("open")) {
					$(this).addClass('open')
					header.addClass('open')
					$menuDropBlock.fadeIn()
				} else {
					$(this).removeClass('open')
					header.removeClass('open')
					$menuDropBlock.fadeOut()
				}
			})
		},
		noLink: function() {
			$('.nolink').on('click', function(e) {
				e.preventDefault()
			})
		},
		headDropDown: function() {
			var $target = $('.dropDown')
			var $header = $('header')

			$target.on('mouseover', function() {
				if ($(window).innerWidth() > 767) {
					$header.addClass('open')
					$(this).find('.menu-dropdown').stop().slideDown(300)
				}

			})

			$target.on('mouseleave', function() {
				if ($(window).innerWidth() > 767) {
					$(this).find('.menu-dropdown').stop().slideUp(100)
					$header.removeClass('open')
				}
			})

			$target.on('click', function() {
				if ($(window).innerWidth() <= 767) {
					if (!$(this).hasClass("open")) {
						$(this).addClass('open')
						$(this).find(".plus").addClass('open')
						$(this).find('.menu-dropdown').slideDown(500)
					} else {
						$(this).removeClass('open')
						$(this).find(".plus").removeClass('open')
						$(this).find('.menu-dropdown').slideUp(500)
					}
				}
			})
		},
		anchor: function() {
			$(document).on('click', 'a[href^="#"]', function(event) {
				event.preventDefault()
				$('html, body').animate({
					scrollTop: $($.attr(this, 'href')).offset().top
				}, 500);
			});
			$('.pageTop').on('click', function(e){
				e.preventDefault()
				$('html, body').animate({
					scrollTop: $('html').offset().top
				}, 500);
			})
			$(window).on('scroll', function(){
				if($(this).scrollTop() > 300){
					$('.footer-scroll').fadeIn()
					$('#topPageBtn').fadeIn()
				}
				else{
					$('.footer-scroll').fadeOut()
					$('#topPageBtn').fadeOut()
				}
			})

			$(window).on('load scroll resize', function(){
				var check = window.pageYOffset ;
				var docHeight = $(document).height();
				var footer = $('footer').height();
				var footerTop = $('.footer-top').height();
				var footerBlock = $('.footer-block').height();
				var footerBottom = $('.footer-bottom').height();

				if (window.innerWidth > 767) {
					if (check > docHeight - footer - 1000 - 120) {
						$('.footer-scroll').css({'position': 'absolute', 'top' : '-220px'})
					}
					else{
						$('.footer-scroll').css({'position': '', 'top': ''})
					}
				}
				else{
					if (check > docHeight - footer - 45) {
						$('.footer-scroll').css({'position': 'absolute', 'bottom' : '45px'})
					}
					else{
						$('.footer-scroll').css({'position': '', 'bottom': ''})
					}
				}


			})

		},
		scrollMagic: function() {
			var controller = new ScrollMagic.Controller()
			var targets = $('.scrollmagic')
			var scenes = []

			targets.each(function() {
				var target = this;
				var scene = new ScrollMagic.Scene({
					triggerElement: target,
					triggerHook: .7
				});
				scene.on('enter', function(e) {
					$(target).addClass('magic')
				});
				scene.on('leave', function(e) {
					// $(target).removeClass('magic')
				});
				scenes.push(scene);
			});
			controller.addScene(scenes);
		},
	}



	$(function() {
		Common.init();
	})
})(jQuery)